import { graphql } from 'gatsby'
import React from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import styled from "styled-components"

const StyledPage = styled.div`
  padding: 3rem 1.5rem;
  
  @media(min-width: 800px) {
    padding: 4rem 10rem;
  }
`;

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 500;
`;

const StyledHr = styled.hr`
  background-color: rgba(230, 50, 50, 0.86);
  width: 90px;
  height: 6px;
  border: none;
  border-radius: 10px;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
`;

const MyPage = ({ data }) => {
  const images = data.datoCmsDiploma.assets;
  return (
    <>
      <Navbar/>
      <StyledPage>
        <StyledH3>{ data.datoCmsDiploma.title }</StyledH3>
        <StyledHr/>
        <Gallery images={images} />
      </StyledPage>
      <Footer/>
    </>
  )
}

export const query = graphql`
  query ImagesForGallery {
    datoCmsDiploma {
      title
      assets {
        thumb: fluid(maxHeight: 320, maxWidth: 720) {
          ...GatsbyDatoCmsFluid
        }
        full: fluid(maxWidth: 1024) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`

export default MyPage